import React, { ReactNode } from 'react';

import cn from 'classnames';

interface Props {
  className?: string;
  href?: string;
  children: ReactNode;
}

const SectionHeader = ({ children, href, className }: Props) => {
  const inner = href ? <a href={href}>{children}</a> : children;
  return (
    <div className={cn('border-dashed border-b border-gray-600', className)}>
      <h2 className='text-abn-red uppercase font-bold text-xl py-2 font-heading'>
        {inner}
      </h2>
    </div>
  );
};

export default SectionHeader;
