import React from 'react';
import cn from 'classnames';
import { wpToCF } from '../../utils/data/cdn';
import styles from './styles.module.css';

interface Props {
  title: string;
  image: string;
  link: string;
  className?: string;
  subTitle?: string;
}

const ArticleSummaryLarge = ({
  title,
  className,
  image,
  link,
  subTitle
}: Props) => {
  const href = link.replace(process.env.UI_URL!, '');
  const imageStyles = {
    backgroundImage: `url(${wpToCF(image)})`
  };
  return (
    <div className={cn('bg-abn-gray-light', className)}>
      <a href={href}>
        <div
          className={`w-full bg-cover ${styles.image}`}
          style={imageStyles}
        />
        {subTitle && <p className='mx-4 my-2 text-xs'>{subTitle}</p>}
        <h3 className='m-4'>{title}</h3>
      </a>
    </div>
  );
};

export default ArticleSummaryLarge;
