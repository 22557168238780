import React from 'react';
import moment from 'moment';

interface Props {
  startDate: string;
  endDate: string;
}

const EventDate = ({ startDate, endDate }: Props) => {
  if (startDate === endDate) {
    return <>{moment(startDate, 'DD/MM/YYYY').format('Do MMMM yyyy')}</>;
  }

  return (
    <>{`${moment(startDate, 'DD/MM/YYYY').format('Do')}-${moment(
      endDate,
      'DD/MM/YYYY'
    ).format('Do MMMM yyyy')}`}</>
  );
};
export default EventDate;
