import React from 'react';

import cn from 'classnames';
import EventDate from '../eventDate';
import styles from './styles.module.css';

interface Props {
  event: WordpressUpcomingEvent;
  className?: string;
}

const UpcomingEvent = ({ event, className }: Props) => {
  const href = `/event/${event.post_name}`;
  const imageStyles = {
    backgroundImage: `url(${event.acf.thumbnail})`
  };
  return (
    <div className={cn(className, 'relative bg-abn-gray-light')}>
      <a href={href}>
        <div
          className={`w-full bg-cover ${styles.image}`}
          style={imageStyles}
        />
        <h3 className='m-4'>
          <strong className='mb-2'>{event.post_title}</strong>
          <br />
          {event.acf.event_location}
          <br />
          <EventDate
            startDate={event.acf.event_start_date}
            endDate={event.acf.event_end_date}
          />
        </h3>
      </a>
    </div>
  );
};

export default UpcomingEvent;
