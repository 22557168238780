import React from 'react';
import { stripTrailingSlash } from '../../utils/data/strings';

interface QLProps {
  seo: SEOQL;
  ogImage?: string;
}

export const SEOFromQL = ({ seo, ogImage }: QLProps) => {
  if (!seo) {
    return null;
  }

  const yoastMeta: YoastMeta[] = [];
  const properties: Record<string, string> = {
    'og:type': seo.opengraphType,
    'og:title': seo.title,
    'og:site_name': seo.opengraphSiteName,
    'og:description': seo.opengraphDescription,
    'article:publisher': seo.opengraphPublisher,
    'article.modified_time': seo.opengraphModifiedTime,
    'twitter:description': seo.twitterDescription,
    'twitter:title': seo.twitterTitle
  };
  const names: Record<string, string> = {
    'twitter:title': seo.twitterTitle
  };

  if (ogImage) {
    yoastMeta.push({
      property: 'og:image',
      content: ogImage
    });
  } else if (seo.opengraphImage) {
    yoastMeta.push({
      property: 'og:image',
      content: seo.opengraphImage.mediaItemUrl
    });
  }

  if (ogImage || seo.twitterImage) {
    yoastMeta.push({
      name: 'twitter:image',
      content: seo.twitterImage ? seo.twitterImage.mediaItemUrl : ogImage!
    });
  }

  Object.keys(properties).forEach(k => {
    if (properties[k]) {
      yoastMeta.push({
        property: k,
        content: properties[k]
      });
    }
  });

  Object.keys(names).forEach(k => {
    if (names[k]) {
      yoastMeta.push({
        name: k,
        content: names[k]
      });
    }
  });

  return <SEO yoastMeta={yoastMeta} />;
};

interface Props {
  yoastMeta?: YoastMeta[];
  ogImage?: string;
}

const SEO = ({ yoastMeta, ogImage }: Props) => {
  const doNotReplace = ['og:image', 'twitter:image'];
  return (
    <>
      {yoastMeta &&
        yoastMeta.map((y, idx) => {
          const nameOrProperty = y.name ? y.name : y.property!;
          let content = '';
          try {
            content =
              doNotReplace.indexOf(nameOrProperty) !== -1
                ? y.content || ''
                : (y.content || y.value || '')
                    .replace('http://', 'https://')
                    .replace(process.env.WORDPRESS_URL!, process.env.UI_URL!);
          } catch (e) {
            content = y.content || '';
          }

          return (
            <meta
              key={idx}
              property={y.property}
              name={y.name}
              content={stripTrailingSlash(content)}
            />
          );
        })}
      {ogImage && <meta property='og:image' content={ogImage} />}
    </>
  );
};

export default SEO;
