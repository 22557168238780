import React from 'react';
import cn from 'classnames';
import { wpToCF } from '../../utils/data/cdn';
import styles from './styles.module.css';

interface Props {
  title: string;
  subTitle?: string;
  image: string;
  link: string;
  className?: string;
  isSponsored?: boolean;
}

const IsSponsored = () => {
  return (
    <div
      className={`${styles.category} uppercase text-white bg-abn-red text-sm whitespace-no-wrap py-1 px-2`}
    >
      Sponsored
    </div>
  );
};

const ArticleSummarySmall = ({
  title,
  subTitle,
  className,
  image,
  link,
  isSponsored = false
}: Props) => {
  const href = link.replace(process.env.UI_URL!, '');
  const imageStyles = {
    backgroundImage: `url(${wpToCF(image)})`
  };
  return (
    <div className={cn(className, 'relative bg-abn-gray-light ')}>
      {isSponsored && <IsSponsored />}

      <a href={href}>
        <div
          className={`w-full bg-cover ${styles.image}`}
          style={imageStyles}
        />
        {subTitle && <p className='text-xs mx-4 my-2'>{subTitle}</p>}
        <h3 className={subTitle ? 'mx-4 mb-4' : 'm-4'}>{title}</h3>
      </a>
    </div>
  );
};

export default ArticleSummarySmall;
