import React from 'react';
import cn from 'classnames';
import Button from '../button';
import { wpToCF } from '../../utils/data/cdn';
import styles from './styles.module.css';

interface Props {
  image: string;
  subtitle: string;
  button: {
    href: string;
    text: string;
  };
}

const Hero = ({ image, subtitle, button }: Props) => {
  const style = {
    backgroundImage: `url(${wpToCF(image)})`
  };

  return (
    <div
      className={cn(styles.hero, 'bg-cover bg-center w-full flex')}
      style={style}
    >
      <div className='flex flex-col justify-center flex-grow text-white'>
        <h2
          className={` font-bold text-3xl sm:text-4xl leading-none ml-8 md:ml-12 w-3/4 font-sans text-shadow`}
        >
          {subtitle}
        </h2>
        {button.href && button.text && (
          <div className='mb-8 ml-8 md:ml-12 md:mb-12'>
            <Button href={button.href}>{button.text}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
