import React from 'react';
import moment from 'moment';
import Head from 'next/head';
import pageWrapper from '../../containers/pageWrapper';
import Layout from '../../components/layout';
import Hero from '../../components/hero';
import SEO from '../../components/seo';
import BannerAd from '../../components/ad/banner';
import SectionHeader from '../../components/sectionHeader';
import ArticleSummarySmall from '../../components/articleSummary/small';
import UpcomingEvent from '../../components/articleSummary/upcomingEvent';
import ArticleSummaryLarge from '../../components/articleSummary/large';
import SideAds, { BlockAdsOne, BlockAdsTwo } from '../../components/sideAds';
import Button from '../../components/button';

import getTitleFromPage from '../../utils/data/getTitleFromPage';
import {
  fetchPage,
  fetchUpcomingEvents,
  fetchEvent
} from '../../utils/data/fetch';
import { fetchHome } from '../../graphql/home';

interface Props {
  error?: boolean;
  page: WordpressPage;
  featuredEvent: WordpressPage;
  menus: WordpressHeaderMenu[];
  latestNews: WordpressHomeSnippet[];
  latestInsights: WordpressHomeSnippet[];
  latestTechnicalArticles: WordpressHomeSnippet[];
  latestPressReleases: WordpressHomeSnippet[];
  latestPodcasts: WordpressHomeSnippet[];
  upcomingEvents: WordpressUpcomingEvent[];
  compEvent: WordpressPage;
  adverts: KeyedAdverts;
}

const Inner = ({
  page,
  latestNews,
  latestInsights,
  latestPressReleases,
  latestPodcasts,
  upcomingEvents,
  adverts
}: Props) => {
  const { acf } = page;
  const { featured_image, subtitle, hero_button } = acf;
  const button = {
    text: hero_button.text,
    href: hero_button.link
  };

  return (
    <>
      <Hero image={featured_image} subtitle={subtitle} button={button} />
      <SectionHeader href='/news'>News</SectionHeader>
      <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-4 sm:gap-4'>
        {latestNews.slice(0, 4).map(news => {
          return (
            <ArticleSummarySmall
              key={news.id}
              className='mt-8 mb-4'
              title={news.title}
              image={news.articleImage.thumbnail?.mediaItemUrl}
              subTitle={moment(news.date).format('Do MMMM yyyy')}
              link={news.link}
            />
          );
        })}
      </div>
      <BannerAd className='mt-8 mb-4' advert={adverts['banner-top']} />
      <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-4 sm:gap-4'>
        {latestNews.slice(-4).map(news => {
          return (
            <ArticleSummarySmall
              key={news.id}
              className='mt-8 mb-4'
              title={news.title}
              image={news.articleImage.thumbnail?.mediaItemUrl}
              subTitle={moment(news.date).format('Do MMMM yyyy')}
              link={news.link}
            />
          );
        })}
      </div>
      <div className='grid grid-cols-1 gap-0 mt-4 sm:grid-cols-2 md:grid-cols-4 sm:gap-4'>
        <div className='col-span-2 md:col-span-3'>
          <SectionHeader href='/insights'>Insights</SectionHeader>
          <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            {latestInsights.map((insight, idx) => {
              const className = idx < 3 ? '' : 'block md:hidden';
              return (
                <ArticleSummarySmall
                  key={insight.id}
                  className={`mb-4 mt-8 ${className}`}
                  title={insight.title}
                  image={insight.articleImage.thumbnail?.mediaItemUrl}
                  link={insight.link}
                  subTitle={moment(insight.date).format('Do MMMM yyyy')}
                />
              );
            })}
          </div>
          <div className='block grid-cols-2 col-span-2 md:hidden md:col-span-1 md:grid-cols-1'>
            <SideAds preventViewEvents={true} adverts={adverts} />
          </div>
          <BlockAdsOne adverts={adverts} />
          <BannerAd className='mt-4 mb-4' advert={adverts['banner-mid']} />

          <Button
            className='block w-full mb-4 text-center uppercase'
            color='red'
            href='/newsletter'
          >
            Newsletter Sign Up →
          </Button>
          <SectionHeader href='/events'>Upcoming Events</SectionHeader>
          <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            {upcomingEvents.map((event, idx) => {
              const className = idx < 3 ? '' : 'block md:hidden';
              return (
                <UpcomingEvent
                  key={idx}
                  className={`mb-4 mt-8 ${className}`}
                  event={event}
                />
              );
            })}
          </div>
          <SectionHeader href='/podcasts'>Latest Recordings</SectionHeader>
          <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            {latestPodcasts.map((podcast, idx) => {
              const className = idx < 3 ? '' : 'block md:hidden';
              return (
                <ArticleSummaryLarge
                  key={podcast.id}
                  className={`mb-4 mt-8 ${className}`}
                  title={podcast.title}
                  image={podcast.articleImage.thumbnail.mediaItemUrl}
                  link={podcast.link}
                  subTitle={moment(podcast.date).format('Do MMMM yyyy')}
                />
              );
            })}
          </div>
          <SectionHeader href='/press_releases'>Press Releases</SectionHeader>
          <div className='grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            {latestPressReleases.map((pr, idx) => {
              const className = idx < 3 ? '' : 'block md:hidden';
              return (
                <ArticleSummarySmall
                  key={pr.id}
                  className={`mb-4 mt-8 ${className}`}
                  title={pr.title}
                  image={pr.articleImage.thumbnail.mediaItemUrl}
                  link={pr.link}
                  subTitle={moment(pr.date).format('Do MMMM yyyy')}
                />
              );
            })}
          </div>
        </div>
        <div className='hidden grid-cols-2 col-span-2 md:block md:col-span-1 md:grid-cols-1'>
          <SideAds adverts={adverts} />
        </div>
      </div>
      <BlockAdsTwo adverts={adverts} />
      <BannerAd className='mt-8 mb-4' advert={adverts['banner-bottom']} />
    </>
  );
};

const Home = ({ error, menus, page, ...props }: Props) => {
  const title = getTitleFromPage('Home');

  if (typeof window !== 'undefined' && window.navigator) {
    console.info('Removings service workers, if any');
    try {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });

      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    } catch (e) {
      console.error('Error clearing service worker caches');
    }
  }

  return (
    <>
      <Head>
        <title key='title'>{title}</title>
        <SEO yoastMeta={page.yoast_meta} />
      </Head>
      <Layout error={error} menus={menus}>
        <Inner menus={menus} page={page} {...props} />
      </Layout>
    </>
  );
};

Home.getInitialProps = async () => {
  try {
    const results = await Promise.all([
      fetchPage('home'),
      fetchUpcomingEvents(4)
    ]);

    const home = results[0];
    const compEvent = await fetchEvent(home.acf.upcoming_event.post_name);
    const snippets = await fetchHome();

    return {
      page: home,
      latestNews: snippets.newses.nodes,
      latestInsights: snippets.insights.nodes,
      latestPressReleases: snippets.pressReleases.nodes,
      latestPodcasts: snippets.podcasts.nodes,
      upcomingEvents: results[1],
      compEvent
    };
  } catch (err) {
    console.error(err);
    return {
      error: true
    };
  }
};

export default pageWrapper(Home);
