import config from '../../config';

export const decode = (str: string) => {
  if (typeof window === 'undefined') {
    return str;
  }
  const elem = window.document.createElement('textarea');
  elem.innerHTML = str;
  return elem.value;
};

export default function getTitleFromPage(pageOrTitle: WordpressPage | string) {
  let showDash = true;
  let title = '';
  if (typeof pageOrTitle === 'string') {
    title = pageOrTitle;
  } else {
    title = decode((pageOrTitle as WordpressPage)?.title?.rendered || '');
  }

  if (title === 'Home') {
    showDash = false;
    title = '';
  }
  return `${config.siteTitle}${showDash ? ' - ' : ''}${title}`;
}
