import { fetchQuery } from '../utils/data/graphql';

const node = `
id
date
title
link
sponsorship {
	articleHasBeenSponsored
}
articleImage {
  thumbnail {
    mediaItemUrl
  }
}
`;

export const homepageQuery = `query {
  newses(first: 8) {
    nodes {
      ${node}
    }
  }
  insights(first: 4) {
    nodes {
      ${node}
    }
  }

  podcasts(first: 4) {
    nodes {
      ${node}
    }
  }
  pressReleases(first: 4) {
    nodes {
      ${node}
    }
  }
}`;

export const fetchHome = async () => {
  const res = await fetchQuery(homepageQuery);
  return res.data;
};
